import { default as actualites_45communautesvT7pK7qGPkMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/actualites-communautes.vue?macro=true";
import { default as _91title_93eWiIwLOMt3Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/[title].vue?macro=true";
import { default as concours_45stellar_45bladejdWtGsmRZYMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/concours-stellar-blade.vue?macro=true";
import { default as indexwtAR9pMzouMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/index.vue?macro=true";
import { default as astrobotAaopzTv7CXMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/astrobot.vue?macro=true";
import { default as astuceswUnl3AE76QMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/astuces.vue?macro=true";
import { default as avantagesd4ZtucD4WOMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/avantages.vue?macro=true";
import { default as avis_45joueursYD7ScqGAtMMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/avis-joueurs.vue?macro=true";
import { default as avis_45mediasqb42t907RTMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/avis-medias.vue?macro=true";
import { default as _91challengeName_93awhDSoSKSIMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/challenge/[challengeName].vue?macro=true";
import { default as challengesU06B99S4jWMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/challenges.vue?macro=true";
import { default as _91postTitle_93WRNAwB0MxZMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/actualites-communaute/[postTitle].vue?macro=true";
import { default as indexRAArFGDVvAMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/actualites-communaute/index.vue?macro=true";
import { default as _91postTitle_93Ndm4THP5hgMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/[subCategoryName]/[postTitle].vue?macro=true";
import { default as indexf8NXf2Mhq2Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/[subCategoryName]/index.vue?macro=true";
import { default as indexzJCq4WRQriMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/index.vue?macro=true";
import { default as _91postTitle_93CWS6ME7XCGMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-joueurs/detail/[postTitle].vue?macro=true";
import { default as indexZ7u0cpDQOAMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-joueurs/index.vue?macro=true";
import { default as _91postTitle_93LzQ1u7HAEbMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-medias/[postTitle].vue?macro=true";
import { default as indexGiGhehy2f0Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-medias/index.vue?macro=true";
import { default as indexFIrSHP535pMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/index.vue?macro=true";
import { default as _91postTitle_93E38FvqH6EqMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/[subCategoryName]/[postTitle].vue?macro=true";
import { default as indexKWrNfnu7u4Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/[subCategoryName]/index.vue?macro=true";
import { default as indexKEy0Y8hqg2Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/index.vue?macro=true";
import { default as indexdpEm82C42hMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/wapers/index.vue?macro=true";
import { default as _91genderName_9353cWeIiyLzMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/genre/[genderName].vue?macro=true";
import { default as indexQqALhFakR4Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/index.vue?macro=true";
import { default as _91typeName_93PqdgW1hm25Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/communautes/type/[typeName].vue?macro=true";
import { default as _91contestName_93LBB8rOz3jBMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/concours-classement/[contestName].vue?macro=true";
import { default as _91contestName_93qeasYmrkwkMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/concours/[contestName].vue?macro=true";
import { default as index3iCG5j5Ys2Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/concours/index.vue?macro=true";
import { default as indexjDZbggsjriMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/index.vue?macro=true";
import { default as inscriptiontzNe4lbPYbMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/inscription.vue?macro=true";
import { default as _91contestName_93BoIsNJ8KZnMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/jeu-concours/[contestName].vue?macro=true";
import { default as landingafH3SBlOZvMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/landing.vue?macro=true";
import { default as le_45coin_45des_45wapersAfv2x4Psa4Meta } from "/home/web/wap/_online/vue_src/nuxt/pages/le-coin-des-wapers.vue?macro=true";
import { default as mon_45profilNEfbhaqJObMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/mon-profil.vue?macro=true";
import { default as _91offerName_93txFPJLCG8lMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/offres/[offerName].vue?macro=true";
import { default as index39H5CLOAPTMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/offres/index.vue?macro=true";
import { default as _91pageName_93VEgI6BcZ4cMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/page/[pageName].vue?macro=true";
import { default as recherche0eJzsbU2wvMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/recherche.vue?macro=true";
import { default as _91tournamentName_93sE1UWNeGkrMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/tournoi/[tournamentName].vue?macro=true";
import { default as _91nickname_93PQrl1DEF7CMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/wapers/[nickname].vue?macro=true";
import { default as index00dw58qPfjMeta } from "/home/web/wap/_online/vue_src/nuxt/pages/wapers/index.vue?macro=true";
export default [
  {
    name: "actualites-communautes",
    path: "/actualites-communautes",
    meta: actualites_45communautesvT7pK7qGPkMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/actualites-communautes.vue").then(m => m.default || m)
  },
  {
    name: "actualites-playstation-title",
    path: "/actualites-playstation/:title()",
    meta: _91title_93eWiIwLOMt3Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/[title].vue").then(m => m.default || m)
  },
  {
    name: "actualites-playstation-concours-stellar-blade",
    path: "/actualites-playstation/concours-stellar-blade",
    meta: concours_45stellar_45bladejdWtGsmRZYMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/concours-stellar-blade.vue").then(m => m.default || m)
  },
  {
    name: "actualites-playstation",
    path: "/actualites-playstation",
    meta: indexwtAR9pMzouMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/actualites-playstation/index.vue").then(m => m.default || m)
  },
  {
    name: "astrobot",
    path: "/astrobot",
    meta: astrobotAaopzTv7CXMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/astrobot.vue").then(m => m.default || m)
  },
  {
    name: "astuces",
    path: "/astuces",
    meta: astuceswUnl3AE76QMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/astuces.vue").then(m => m.default || m)
  },
  {
    name: "avantages",
    path: "/avantages",
    meta: avantagesd4ZtucD4WOMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/avantages.vue").then(m => m.default || m)
  },
  {
    name: "avis-joueurs",
    path: "/avis-joueurs",
    meta: avis_45joueursYD7ScqGAtMMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/avis-joueurs.vue").then(m => m.default || m)
  },
  {
    name: "avis-medias",
    path: "/avis-medias",
    meta: avis_45mediasqb42t907RTMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/avis-medias.vue").then(m => m.default || m)
  },
  {
    name: "challenge-challengeName",
    path: "/challenge/:challengeName()",
    meta: _91challengeName_93awhDSoSKSIMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/challenge/[challengeName].vue").then(m => m.default || m)
  },
  {
    name: "challenges",
    path: "/challenges",
    meta: challengesU06B99S4jWMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/challenges.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-actualites-communaute-postTitle",
    path: "/communautes/:communityName()/actualites-communaute/:postTitle()",
    meta: _91postTitle_93WRNAwB0MxZMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/actualites-communaute/[postTitle].vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-actualites-communaute",
    path: "/communautes/:communityName()/actualites-communaute",
    meta: indexRAArFGDVvAMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/actualites-communaute/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-astuces-subCategoryName-postTitle",
    path: "/communautes/:communityName()/astuces/:subCategoryName()/:postTitle()",
    meta: _91postTitle_93Ndm4THP5hgMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/[subCategoryName]/[postTitle].vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-astuces-subCategoryName",
    path: "/communautes/:communityName()/astuces/:subCategoryName()",
    meta: indexf8NXf2Mhq2Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/[subCategoryName]/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-astuces",
    path: "/communautes/:communityName()/astuces",
    meta: indexzJCq4WRQriMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/astuces/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-avis-joueurs-detail-postTitle",
    path: "/communautes/:communityName()/avis-joueurs/detail/:postTitle()",
    meta: _91postTitle_93CWS6ME7XCGMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-joueurs/detail/[postTitle].vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-avis-joueurs",
    path: "/communautes/:communityName()/avis-joueurs",
    meta: indexZ7u0cpDQOAMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-joueurs/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-avis-medias-postTitle",
    path: "/communautes/:communityName()/avis-medias/:postTitle()",
    meta: _91postTitle_93LzQ1u7HAEbMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-medias/[postTitle].vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-avis-medias",
    path: "/communautes/:communityName()/avis-medias",
    meta: indexGiGhehy2f0Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/avis-medias/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName",
    path: "/communautes/:communityName()",
    meta: indexFIrSHP535pMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-le-coin-des-wapers-subCategoryName-postTitle",
    path: "/communautes/:communityName()/le-coin-des-wapers/:subCategoryName()/:postTitle()",
    meta: _91postTitle_93E38FvqH6EqMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/[subCategoryName]/[postTitle].vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-le-coin-des-wapers-subCategoryName",
    path: "/communautes/:communityName()/le-coin-des-wapers/:subCategoryName()",
    meta: indexKWrNfnu7u4Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/[subCategoryName]/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-le-coin-des-wapers",
    path: "/communautes/:communityName()/le-coin-des-wapers",
    meta: indexKEy0Y8hqg2Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/le-coin-des-wapers/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-communityName-wapers",
    path: "/communautes/:communityName()/wapers",
    meta: indexdpEm82C42hMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/[communityName]/wapers/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-genre-genderName",
    path: "/communautes/genre/:genderName()",
    meta: _91genderName_9353cWeIiyLzMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/genre/[genderName].vue").then(m => m.default || m)
  },
  {
    name: "communautes",
    path: "/communautes",
    meta: indexQqALhFakR4Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/index.vue").then(m => m.default || m)
  },
  {
    name: "communautes-type-typeName",
    path: "/communautes/type/:typeName()",
    meta: _91typeName_93PqdgW1hm25Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/communautes/type/[typeName].vue").then(m => m.default || m)
  },
  {
    name: "concours-classement-contestName",
    path: "/concours-classement/:contestName()",
    meta: _91contestName_93LBB8rOz3jBMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/concours-classement/[contestName].vue").then(m => m.default || m)
  },
  {
    name: "concours-contestName",
    path: "/concours/:contestName()",
    meta: _91contestName_93qeasYmrkwkMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/concours/[contestName].vue").then(m => m.default || m)
  },
  {
    name: "concours",
    path: "/concours",
    meta: index3iCG5j5Ys2Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/concours/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "inscription",
    path: "/inscription",
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/inscription.vue").then(m => m.default || m)
  },
  {
    name: "jeu-concours-contestName",
    path: "/jeu-concours/:contestName()",
    meta: _91contestName_93BoIsNJ8KZnMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/jeu-concours/[contestName].vue").then(m => m.default || m)
  },
  {
    name: "landing",
    path: "/landing",
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "le-coin-des-wapers",
    path: "/le-coin-des-wapers",
    meta: le_45coin_45des_45wapersAfv2x4Psa4Meta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/le-coin-des-wapers.vue").then(m => m.default || m)
  },
  {
    name: "mon-profil",
    path: "/mon-profil",
    meta: mon_45profilNEfbhaqJObMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/mon-profil.vue").then(m => m.default || m)
  },
  {
    name: "offres-offerName",
    path: "/offres/:offerName()",
    meta: _91offerName_93txFPJLCG8lMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/offres/[offerName].vue").then(m => m.default || m)
  },
  {
    name: "offres",
    path: "/offres",
    meta: index39H5CLOAPTMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/offres/index.vue").then(m => m.default || m)
  },
  {
    name: "page-pageName",
    path: "/page/:pageName()",
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/page/[pageName].vue").then(m => m.default || m)
  },
  {
    name: "recherche",
    path: "/recherche",
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/recherche.vue").then(m => m.default || m)
  },
  {
    name: "tournoi-tournamentName",
    path: "/tournoi/:tournamentName()",
    meta: _91tournamentName_93sE1UWNeGkrMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/tournoi/[tournamentName].vue").then(m => m.default || m)
  },
  {
    name: "wapers-nickname",
    path: "/wapers/:nickname()",
    meta: _91nickname_93PQrl1DEF7CMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/wapers/[nickname].vue").then(m => m.default || m)
  },
  {
    name: "wapers",
    path: "/wapers",
    meta: index00dw58qPfjMeta || {},
    component: () => import("/home/web/wap/_online/vue_src/nuxt/pages/wapers/index.vue").then(m => m.default || m)
  }
]